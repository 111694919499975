import React from 'react'
import styled from 'styled-components'
import logoMark from '../assets/images/logomark_gold.png'
import { FormattedMessage } from 'react-intl'

const HeaderContainer = styled.header`
  background: #181818;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 800px) {
    ul {
      display: none;
    }
  }

  img {
    width: 30px;
    height: 30px;
    margin: 0;
    display: block;
  }
`

const Menu = styled.ul`
  margin: 0 auto;
  display: flex;
  flex-direction: row;

  li {
    margin: 0;
    list-style: none;

    &:not(:last-child) {
      margin-right: 1em;
    }

    a {
      font-weight: 400;
      font-size: 0.7em;
      text-transform: uppercase;
      color: white;
      text-decoration: none;
    }
  }
`

const Right = styled.div`
  a {
    color: #fff;
    text-decoration: none;
    display: inline-block;
    :not(:last-child) {
      margin-right: 1em;
    }
  }
`

const Header = () => (
  <HeaderContainer>
    <img src={logoMark} alt="logo" />
    <Menu>
      <li>
        <a href="#practice">
          <FormattedMessage id="menu.practice-areas" />
        </a>
      </li>
      <li>
        <a href="#about-us">
          <FormattedMessage id="menu.about-us" />
        </a>
      </li>
      <li>
        <a href="#our-team">
          <FormattedMessage id="menu.our-team" />
        </a>
      </li>
      <li>
        <a href="#testimonials">
          <FormattedMessage id="menu.testimonials" />
        </a>
      </li>
      <li>
        <a href="#contact-form">
          <FormattedMessage id="menu.contact-us" />
        </a>
      </li>
    </Menu>
    <Right>
      <a href="/">EN</a>
      <a href="/es">ES</a>
      <a href="/cn">CN</a>
    </Right>
  </HeaderContainer>
)

export default Header
